import { all } from 'redux-saga/effects';
import categorySaga from './category/redux/category.saga';
import mPageSaga from './m-page/redux/m-page.saga';
import mpagesSaga from './mpages/redux/mpages.saga';

export function* rootSaga() {
    yield all([
        mpagesSaga(),
        categorySaga(),
        mPageSaga()
    ])
}

