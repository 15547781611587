export const fetchPageDetailsAction = (pageId) => {
    return {
        type: 'PAGE_DETAILS_FETCH_REQUESTED',
        payload: { pageId }
    }
}

export const fetchPageDetailsSucceededAction = (pageDetails) => {
    return {
        type: 'PAGE_DETAILS_FETCH_SUCCEEDED',
        payload: { pageDetails }
    }
}

export const fetchPageDetailsFailedAction = (errorMsg) => {
    return {
        type: 'PAGE_DETAILS_FETCH_FAILED',
        payload: { errorMsg }
    }
}

export const incPageViewAction = (page) => {
    return {
        type: 'INC_PAGE_VIEW',
        payload: { page }
    }
}

export const incPageViewSuccessAction = (page) => {
    return {
        type: 'INC_PAGE_VIEW_SUCCESS',
        payload: { page }
    }
}

export const incPageViewFailedAction = (errorMsg) => {
    return {
        type: 'INC_PAGE_VIEW_FAILED',
    }
}




