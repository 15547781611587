import React from "react";
import ReactGA from "react-ga";
import TopCategoriesComponent from "../../category/components/top-categories/top-categories.component";
import SponsoredPagesComponent from "../../mpages/components/sponsored-pages/sponsored-pages.component";
import TopPagesComponent from "../../mpages/components/top-pages/top-pages.component";
import NavbarComponent from "../../shared/components/navbar/navbar.component";
import FooterSmallComponent from "../../shared/components/footer-small/footer-small.component";
import { withRouter } from "react-router-dom";

class HomePageComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount = async () => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        let stats = await fetch("/api/stats");
        stats = await stats.json();
        if (stats) {
            this.setState({ ...this.state, ...{ stats } });
        }
    };

    navigateToSearchPage = (page) => {
        this.props.history.push("/pages");
    };

    navigateToAddYourBusiness = () => {
        this.props.history.push("/add-your-business");
    };

    getClassName = () => {
        if (this.state.spinnerVal) {
            return "fas fa-spinner fa-pulse";
        } else {
            return "fas fa-search";
        }
    };

    stripHtml = (htmlString) => {
        return htmlString
            ? htmlString
                  .replace(/<[^>]*>?/gm, " ")
                  .replace(/\s+/g, " ")
                  .trim()
            : "";
    };

    render() {
        return (
            <>
                <NavbarComponent />
                <header
                    className="masthead"
                    style={{
                        backgroundImage: 'url("mp-bg.webp")',
                    }}
                >
                    <div className="container h-100">
                        <div className="row h-100 align-items-center justify-content-center">
                            <div className="col-lg-10 align-self-end">
                                <h1
                                    className="text-white font-weight-bold"
                                    title="मराठी Pages"
                                >
                                    <span style={{ color: "orange" }}>
                                        मराठी
                                    </span>{" "}
                                    Pages.<small>in</small>
                                    <br></br>
                                </h1>
                                <h3 className="text-white font-weight-bold">
                                    LOCAL BUSINESS DIRECTORY & MINI-WEBSITES
                                </h3>
                                <br></br>
                                <div
                                    style={{ position: "relative" }}
                                    onClick={this.navigateToSearchPage}
                                >
                                    <div className="input-group mb-3">
                                        <input
                                            style={{ height: "60px" }}
                                            type="text"
                                            className="form-control"
                                            placeholder="Search for a page, business, mini-website."
                                            aria-label="Recipient's username"
                                            aria-describedby="basic-addon2"
                                        />
                                        <div className="input-group-append">
                                            <button
                                                className="btn btn-primary"
                                                type="button"
                                            >
                                                <div className="p-2">
                                                    <i
                                                        className={
                                                            this.state
                                                                .spinnerVal
                                                                ? "fas fa-spinner fa-pulse"
                                                                : "fas fa-search"
                                                        }
                                                    >
                                                        {" "}
                                                    </i>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <br></br>
                                <button
                                    onClick={this.navigateToAddYourBusiness}
                                    type="button"
                                    className="btn btn-warning btn-whitesmoke text-white"
                                >
                                    Add Your Business
                                </button>
                            </div>
                            <div className="col-lg-8 align-self-baseline">
                                <p
                                    className="text-white-75 font-weight-light mb-5"
                                    dangerouslySetInnerHTML={{
                                        __html: this.props?.page?.subTitle,
                                    }}
                                ></p>
                            </div>
                        </div>
                    </div>
                </header>

                <section className="text-center p-5 bg-primary text-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 mb-5 mb-md-0">
                                <h3>
                                    {this.state?.stats?.totalPageViews}
                                    <sup>+</sup>
                                    <br></br>PAGE HITS
                                </h3>
                            </div>
                            <div className="col-md-3 mb-5 mb-md-0">
                                <h3>
                                    {this.state?.stats?.totalCategories}
                                    <sup>+</sup>
                                    <br></br>CATEGORIES
                                </h3>
                            </div>
                            <div className="col-md-3 mb-5 mb-md-0">
                                <h3>
                                    {this.state?.stats?.totalCities}
                                    <sup>+</sup> <br></br>CITIES
                                </h3>
                            </div>
                            <div className="col-md-3 mb-5 mb-md-0">
                                <h3>
                                    {this.state?.stats?.totalPages}
                                    <sup>+</sup> <br></br>PAGES
                                </h3>
                            </div>
                        </div>
                    </div>
                </section>

                <TopCategoriesComponent></TopCategoriesComponent>

                <SponsoredPagesComponent></SponsoredPagesComponent>

                <TopPagesComponent></TopPagesComponent>

                <FooterSmallComponent />
            </>
        );
    }
}

export default withRouter(HomePageComponent);
