import React from 'react';
import { COPY_RIGHT_YEAR } from '../../constants'

const FooterSmallComponent = (props) => {
    return <>
        <footer className="bg-dark py-5" >
            <div className="card-actions">
                <a href="mailto:marathipages.sales@gmail.com?subject=Marathi%20Page%20Creation%20For%20My%20Business&body=message%20goes%20here" target="_blank" rel="noopener noreferrer" className="card-action hand">
                    <i className="fa fa-envelope"></i>
                </a>
                <a href="https://www.facebook.com/marathipages/" target="_blank" rel="noopener noreferrer" className="card-action hand">
                    <i className="fab fa-facebook"></i>
                </a>
                <a href="https://www.youtube.com/channel/UCdSr3cYutf9gLy1i7YTkiBg" target="_blank" rel="noopener noreferrer" className="card-action hand">
                    <i className="fab fa-youtube"></i>
                </a>
                <a href="https://www.instagram.com/marathipages/" target="_blank" rel="noopener noreferrer" className="card-action hand">
                    <i className="fab fa-instagram"></i>
                </a>
                <a href="https://t.me/marathipages" target="_blank" rel="noopener noreferrer" className="card-action hand">
                    <i className="fab fa-telegram"></i>
                </a>
            </div>
            <div className="container">
                <div className="small text-center text-muted">
                    <a href="/terms-conditions" style={{ textDecoration: 'none', color: '#6c757d' }}>Terms & Conditions </a> | <a href="/privacy-policy" style={{ textDecoration: 'none', color: '#6c757d' }}>Privacy Policy</a>
                </div>
            </div>
            <div className="container">
                <div className="small text-center text-muted">
                    Copyright © {COPY_RIGHT_YEAR} - मराठी Pages
                </div>
            </div>
        </footer >
    </>
}

export default FooterSmallComponent;







