
export const fetchSponsoredPagesAction = () => {
    return {
        type: 'SPONSORED_PAGES_FETCH_REQUESTED'
    }
}

export const fetchTopPagesAction = () => {
    return {
        type: 'TOP_PAGES_FETCH_REQUESTED'
    }
}

export const fetchAllPagesAction = (_params) => {
    return {
        type: 'ALL_PAGES_FETCH_REQUESTED',
        payload: _params
    }
}