import React from 'react';
import { connect } from 'react-redux';
import { fetchAllPagesAction } from '../../mpages/redux/mpages.action'
import MpageCardComponent from '../../mpages/components/mpage-card/mpage-card.component';
import NavbarComponent from '../../shared/components/navbar/navbar.component';
import FiltersComponent from '../../filters/components/filters/filters.component';

import {
    setAppliedFiltersAction,
    setSearchTextAction,
    toggleFiltersAction
} from '../../filters/redux/filters.action';

class SearchPageComponent extends React.Component {

    objectToQueryString = (obj) => {
        return Object.keys(obj).map(key => key + '=' + obj[key]).join('&');
    }

    onInputchange(event) {
        this.props.setSearchTextAction(event.target.value);
        this.props.fethcAllPagesSummary({ ...this.props.filters, searchText: event.target.value })
    }

    constructor(props) {
        super(props);
        this.onInputchange = this.onInputchange.bind(this);
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        this.props.fethcAllPagesSummary(this.props.filters);
        this.props.setAppliedFiltersAction();
    }

    stripHtml = (htmlString) => {
        return htmlString ? htmlString.replace(/<[^>]*>?/gm, ' ').replace(/\s+/g, ' ').trim() : ''
    }

    render() {
        return (
            <>
                <NavbarComponent />
                <section className="pt-5">
                    <div className="container mt-5">
                        <div>
                            <div style={{ position: 'relative' }}>
                                <div className="input-group mb-3">
                                    <input name="searchText"
                                        onChange={this.onInputchange}
                                        style={{ height: '60px' }}
                                        type="text"
                                        className="form-control"
                                        placeholder="Search for a page, business, mini-website."
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                        value={this.props.filters.searchText} />
                                    <div className="input-group-append">
                                        <button className="btn btn-primary" type="button" onClick={() => this.props.toggleFiltersAction()}>
                                            <div className="p-2">
                                                <i className={this.state?.spinnerVal ? 'fas fa-spinner fa-pulse' : 'fas fa-filter'}> </i>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                                <div className="alert alert-dark" style={{ background: 'whitesmoke' }}>
                                    <div>
                                        <div className='small font-weight-bold text-uppercase'>Applied Filters </div>
                                        {this.props?.categories?.map((_category, index) => {
                                            return <span key={_category.displayName}>{
                                                this.props?.filters?.appliedFilters?.categories?.[_category._id] ?
                                                    <span className="badge badge-dark mr-1" key={_category._id}>{_category.displayName}</span> :
                                                    <></>
                                            }</span>
                                        })}
                                        <div>
                                            <span className="badge badge-dark mr-1">{this.props?.filters?.appliedFilters?.ratings < 5 ? this.props?.filters?.appliedFilters?.ratings + ' stars & above' : '5 stars'} </span>
                                            {/* <span className="badge badge-light mr-1">Showing {this.props?.filters?.appliedFilters?.pageSize} Results</span> */}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="alert alert-info" role="alert">
                                    No results found.
                                </div> */}
                                {this.props?.filters?.isFilters ?
                                    <div style={{ top: '62px', backgroundColor: 'white', zIndex: 2, position: 'absolute', width: '100%', maxHeight: '450px', overflowY: 'scroll', borderRadius: '3px', border: '0.1px solid #0000001c' }}>
                                        {/* <div className="p-3">
                                        <i className="fas fa-spinner fa-pulse"></i> Searching...
                                    </div> */}
                                        <FiltersComponent></FiltersComponent>
                                    </div>
                                    : <></>}
                            </div>
                        </div>
                    </div>
                </section>

                <section className="text-center">
                    <div className="container">
                        <div className="row">
                            {this.props?.allPages?.map((page, index) => {
                                return page.status === 'PUBLISHED' ? <div key={index} className="col-lg-3 col-md-6 text-center">
                                    <div className="mt-5">
                                        <MpageCardComponent page={page}></MpageCardComponent>
                                    </div>
                                </div> : ''
                            })}
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

const mapStateToProps = (_state) => {
    return {
        allPages: _state.mpages.allPages,
        categories: _state.category.list,
        searchPage: _state.searchPage,
        filters: _state.filters
    }
}

const mapDispatchToProps = (_dispatch) => {
    return {
        fethcAllPagesSummary: (_params) => _dispatch(fetchAllPagesAction(_params)),
        toggleFiltersAction: () => _dispatch(toggleFiltersAction()),
        setSearchTextAction: (_searchText) => _dispatch(setSearchTextAction(_searchText)),
        setAppliedFiltersAction: (_filters) => _dispatch(setAppliedFiltersAction()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchPageComponent)