import React from 'react';

const NavbarComponent = (props) => {

    return <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top py-3 navbar-scrolled text-white" id="mainNav">
        <div className="container">
            <a className="navbar-brand js-scroll-trigger text-white" href="/">
                <span style={{ background: 'orange', padding: '5px' }}>
                    मराठी
                </span>
                &nbsp; Pages
            </a>
            <div className="collapse navbar-collapse" id="navbarResponsive">
                <ul className="navbar-nav ml-auto my-2 my-lg-0"></ul>
            </div>
        </div>
    </nav>

}

export default NavbarComponent;