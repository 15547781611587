let filtersInitState = {
    categories: {},
    isFilters: false,
    pageSize: 50,
    ratings: 1,
    searchText: '',
    appliedFilters: {}
};
export const filtersReducer = (state = filtersInitState, action) => {
    switch (action.type) {
        case 'TOGGLE_FILTERS': return { ...state, ...{ isFilters: !state.isFilters } }
        case 'SET_CATEGORIES': return { ...state, ...{ categories: action.payload.categories } }
        case 'SET_RATINGS': return { ...state, ...{ ratings: action.payload.ratings } }
        case 'SET_PAGE_SIZE': return { ...state, ...{ pageSize: action.payload.pageSize } }
        case 'SET_SEARCH_TEXT': return { ...state, ...{ searchText: action.payload.searchText } }
        case 'SET_APPLIED_FILTERS': return { ...state, appliedFilters: state }
        default: return state
    }
}