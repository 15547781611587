import React from 'react';
import { connect } from 'react-redux';

const ContactsSectionComponent = (props) => {
    return <section className="page-section" id="contact">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-8 text-center">
                    <h2 className="mt-0" dangerouslySetInnerHTML={{ __html: props?.page?.contactHeader }}></h2>
                    <hr className="divider my-4" />
                    <p className="text-muted mb-5" dangerouslySetInnerHTML={{ __html: props?.page?.contactDescription }}></p>
                </div>
            </div>
            <div className="row">
                {props?.page?.contactNumber ?
                    <div className="col-lg-4 ml-auto text-center mb-5 mb-lg-0">
                        <i className="fas fa-phone fa-3x mb-3 text-muted"></i>
                        <div itemScope itemType={"https://marathipages.in/" + props?.page?.unqUrl}>
                            <span itemProp="telephone">
                                <a href={"tel:" + props?.page?.contactNumber}>{props?.page?.contactNumber}</a>
                            </span>
                        </div>
                    </div>
                    : ''}
                {props?.page?.email ?
                    <div className="col-lg-4 mr-auto text-center">
                        <i className="fas fa-envelope fa-3x mb-3 text-muted"></i>
                        <a className="d-block" href={"mailto:" + props?.page?.email}>{props?.page?.email}</a>
                    </div>
                    : ''}
                {props?.page?.address ?
                    <div className="col-lg-4 ml-auto text-center mb-5 mb-lg-0">
                        <i className="fas fa-map-marker fa-3x mb-3 text-muted"></i>
                        <div>{props?.page?.address}</div>
                    </div>
                    : ''}
            </div>
        </div>
    </section>
}

const mapStateToProps = (state) => {
    return {
        page: state.mpage.data || {},
    }
}

export default connect(mapStateToProps, null)(ContactsSectionComponent)