import React from 'react';
import { connect } from 'react-redux';

const CoverVideoComponent = (props) => {
    return <>
        {
            props?.page?.coverVideo?.length > 2 ?
                <section className="page-section" id="about">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="embed-responsive embed-responsive-16by9 align-middle">
                                <iframe title="coverVideo" className="embed-responsive-item" src={props.page.coverVideo} allow={props.page.coverVideoProps}></iframe>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        fontSize: '10px',
                        color: 'brown',
                        textAlign: 'center'
                    }}><small>*soon this will be deprecated contact admin</small>
                    </div>
                </section>
                : ''
        }
    </>
}

const mapStateToProps = (state) => {
    return {
        page: state.mpage.data || {},
    }
}

export default connect(mapStateToProps)(CoverVideoComponent)