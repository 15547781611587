import React from "react";
import { connect } from "react-redux";

const CoverSectionComponent = (props) => {
    let getCategory = (page) => {
        let lable;
        if (page) {
            if (page.categoryNext) {
                lable = page.categoryNext.displayName;
            } else {
                let category = page.category;
                if (category === "0") {
                    lable = "Business";
                } else if (category === "1") {
                    lable = "Entertainment";
                } else if (category === "2") {
                    lable = "Portfolio";
                } else if (category === "3") {
                    lable = "Nonprofit";
                } else if (category === "4") {
                    lable = "Educational";
                } else if (category === "5") {
                    lable = "Personal";
                } else if (category === "6") {
                    lable = "Story";
                } else if (category === "7") {
                    lable = "Jobs";
                } else {
                    lable = "Other";
                }
            }
        }
        return lable;
    };

    return (
        <>
            <header
                className="masthead"
                style={
                    props?.page?.coverImage
                        ? {
                              backgroundImage:
                                  "url(" + props.page.coverImage + ")",
                          }
                        : {}
                }
            >
                <div className="container h-100">
                    <div className="row h-100 align-items-center justify-content-center text-center">
                        <div className="col-lg-10 align-self-end">
                            <h1
                                className="text-white font-weight-bold"
                                dangerouslySetInnerHTML={{
                                    __html: props.page?.title,
                                }}
                            ></h1>
                            <hr className="divider my-4" />
                        </div>
                        <div className="col-lg-8 align-self-baseline">
                            <h2
                                className="text-white-75 font-weight-light mb-5 h5"
                                dangerouslySetInnerHTML={{
                                    __html: props?.page?.subTitle,
                                }}
                            ></h2>
                            <span
                                className="badge badge-primary js-scroll-trigger text-white"
                                style={{ padding: "10px", marginRight: "5px" }}
                            >
                                {getCategory(props.page)}
                            </span>
                            {props?.page?.verification ? (
                                <span
                                    className="badge badge-primary js-scroll-trigger text-white"
                                    style={{
                                        padding: "10px",
                                        marginRight: "5px",
                                    }}
                                >
                                    Verified
                                </span>
                            ) : (
                                ""
                            )}
                            <span
                                className="badge badge-primary js-scroll-trigger text-white"
                                style={{ padding: "10px", marginRight: "5px" }}
                            >
                                {props?.page?.views ? props?.page?.views : "0"}{" "}
                                views
                            </span>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

const mapStateToProps = (_state) => {
    return {
        page: _state.mpage.data || {},
    };
};

export default connect(mapStateToProps, null)(CoverSectionComponent);
