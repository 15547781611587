import { put, takeEvery } from 'redux-saga/effects';

function* fetchCategories(action) {
    try {
        let categoriesRes = yield fetch('/api/categories?sort=name Asc');
        categoriesRes = yield categoriesRes.json();
        if (categoriesRes?.count) {
            yield put({ type: "CATEGORIES_FETCH_SUCCEEDED", payload: { categories: categoriesRes?.list } });
        }
    } catch (e) {
        yield put({ type: "CATEGORIES_FETCH_FAILED", message: e.message });
    }
}

function* categorySaga() {
    yield takeEvery("CATEGORIES_FETCH_REQUESTED", fetchCategories);
}

export default categorySaga;