import React from 'react';
import { connect } from 'react-redux';

const ProductsSectionComponent = (props) => {
    return <section className={'page-section ' + props?.page?.productsSectionClass} id="productsSection">
        <div className="container">
            {
                props?.page?.productsSectionTitle ? <>
                    <h2 className="text-center mt-0">{props.page.productsSectionTitle}</h2>
                    <hr className="divider my-4" />
                </> : ''
            }
            <div className="row">
                {props?.page?.products?.map((prd, index) => {
                    return <div key={index} className={prd.class}>
                        <div className="mt-5">
                            {
                                prd.url ? <>
                                    <div className="card-image" style={{ backgroundImage: 'url(' + prd.url + ')' }} variant="top" ></div>
                                </> : ''
                            }
                            {
                                prd.icon ? <>
                                    <i className={prd.icon}></i>
                                </> : ''
                            }
                            {
                                prd.title ? <>
                                    <h4 className="mb-2 mt-4" dangerouslySetInnerHTML={{ __html: prd?.title }}></h4>
                                </> : ''
                            }
                            {
                                prd.price ? <>
                                    <div className="mb-2 mt-4">
                                        <b>&#x20B9; {prd.price} </b>
                                        <small>{prd.priceDesc}</small>
                                    </div>
                                </> : ''
                            }
                            {
                                prd.value ? <>
                                    <p className="mb-0 mt-4" dangerouslySetInnerHTML={{ __html: prd?.value }}></p>
                                </> : ''
                            }
                        </div>
                    </div>
                })}
            </div>
        </div>
        <div style={{
            fontSize: '10px',
            color: 'brown',
            textAlign: 'center'
        }}><small>*soon this will be deprecated contact admin</small>
        </div>
    </section>
}

const mapStateToProps = (state) => {
    return {
        page: state.mpage.data || {},
    }
}

export default connect(mapStateToProps, null)(ProductsSectionComponent)