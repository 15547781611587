export class EnquiryForm {
    enquiryApiUrl = '/api/individual-page-inquiry';
    GLOBAL_ALERT_NAME = "globalAlert"
    setForm() {
        this.form = document.getElementById(this.configs.formName);
    }
    setGlobalAlert() {
        this.globalAlert = document.getElementById(this.GLOBAL_ALERT_NAME);
    }
    resetForm() {
        this.form.classList.remove('was-validated');
        this.form.reset();
    }
    resetGlobalAlert() {
        this.globalAlert.hidden = true;
    }
    handleSuccess() {
        this.globalAlert.innerText = this.configs.enquirySuccessMsg;
        this.globalAlert.hidden = false;
        this.globalAlert.className = "alert alert-success";
        setTimeout(() => {
            this.resetForm();
            this.resetGlobalAlert();
        }, 3000)
    }
    handleError() {
        this.globalAlert.innerText = this.configs.enquiryErrorMsg;
        this.globalAlert.hidden = false;
        this.globalAlert.className = "alert alert-danger";
        setTimeout(() => {
            this.resetGlobalAlert();
        }, 3000)
    }
    getRestOptions(_json) {
        return {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(_json)
        }
    }
    async createEnquiry(_json) {
        let res = await fetch(this.enquiryApiUrl, this.getRestOptions(_json));
        res = await res.json();
        if (res && res._id) {
            this.handleSuccess();
        } else {
            this.handleError();
        }
    }
    setConfigs(_configs) {
        this.configs = _configs;
    }
    setEnquiryApiUrl() {
        this.enquiryApiUrl = this.configs.domain + this.enquiryApiUrl;
    }
    getJson() {
        let _json = { data: {}, pageId: this.configs.pageId, pageTitle: this.configs.pageTitle };
        Array.from(this.form.elements).forEach((input) => {
            if (input.id)
                _json.data[input.id] = input.value;
        });
        return _json;
    }
    onFormSubmit(_event) {
        _event.preventDefault();
        _event.stopPropagation();
        if (this.form.checkValidity()) {
            this.createEnquiry(this.getJson());
        }
        this.form.classList.add('was-validated');
    }
    setOnSubmitEventListner() {
        this.form.addEventListener('submit', this.onFormSubmit.bind(this), false);
    }
    setFormDetails() {
        this.setForm();
        this.setGlobalAlert();
        this.setEnquiryApiUrl();
        this.setOnSubmitEventListner();
    }

    configure(_configs) {
        this.setConfigs(_configs);
        this.buildForm();
        this.setFormDetails();
    }



    createLabelEle(_meta) {
        let label = document.createElement('label');
        label.setAttribute('for', _meta.for);
        label.innerText = _meta.text;
        return label;
    }
    createValidationEle(_meta) {
        let validationEle = document.createElement('div');
        validationEle.innerText = _meta.message;
        validationEle.classList.add('invalid-feedback');
        return validationEle;
    }
    createInputEle(_meta) {
        let inputEle = document.createElement('input');
        _meta.attrs.forEach(atr => {
            inputEle.setAttribute(atr.key, atr.val);
        });
        inputEle.classList.add('form-control')
        return inputEle;
    }

    createDivWrapper(_meta) {
        let formFieldWrapper = document.createElement('div');
        formFieldWrapper.className = _meta.wrapperClass;
        formFieldWrapper.append(this.createLabelEle(_meta.label))
        formFieldWrapper.append(this.createInputEle(_meta.input))
        formFieldWrapper.append(this.createValidationEle(_meta.validation))
        return formFieldWrapper;
    }

    createSubmitButton(_meta) {
        let submitButtonWrapper = document.createElement('div');
        submitButtonWrapper.className = _meta.wrapperClass;
        let btn = document.createElement('button');
        btn.className = _meta.button.class;
        btn.setAttribute("type", "submit");
        btn.innerText = _meta.button.text;
        submitButtonWrapper.append(btn);
        return submitButtonWrapper
    }

    createAlertEle(_meta) {
        let alertWapperEle = document.createElement('div');
        alertWapperEle.className = _meta.wrapperClass;
        let alertEle = document.createElement('div');
        alertEle.setAttribute("role", "alert");
        alertEle.setAttribute("id", this.GLOBAL_ALERT_NAME)
        alertEle.setAttribute("hidden", "true")
        alertWapperEle.append(alertEle);
        return alertWapperEle
    }

    buildForm() {
        let _enquiryForm = document.querySelector(`#${this.configs.formName} .form-row`);

        this.configs.fields.forEach((fld) => {
            _enquiryForm.append(this.createDivWrapper(fld));
        })

        _enquiryForm.append(this.createAlertEle(this.configs.globalAlert))
        _enquiryForm.append(this.createSubmitButton(this.configs.submitButton))
    }
}



