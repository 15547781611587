export const toggleFiltersAction = () => {
    return {
        type: 'TOGGLE_FILTERS'
    }
}

export const setCategoriesAction = (_categories) => {
    return {
        type: 'SET_CATEGORIES',
        payload: { categories: _categories }
    }
}

export const setRatingsAction = (_ratings) => {
    return {
        type: 'SET_RATINGS',
        payload: { ratings: _ratings }
    }
}

export const setPageSizeAction = (_pageSize) => {
    return {
        type: 'SET_PAGE_SIZE',
        payload: { pageSize: _pageSize }
    }
}

export const setSearchTextAction = (_searchText) => {
    return {
        type: 'SET_SEARCH_TEXT',
        payload: { searchText: _searchText }
    }
}

export const setAppliedFiltersAction = () => {
    return {
        type: 'SET_APPLIED_FILTERS',
    }
}