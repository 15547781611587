import React from 'react';
import AddBusinessFormComponent from '../add-business-form/add-business-form.component';
import { COPY_RIGHT_YEAR } from '../../constants';

const FooterComponent = (props) => {
    return <>
        <footer className="bg-dark py-5">
            <div className="container">
                <div className="row">
                    <div className="col-sm">
                        <AddBusinessFormComponent />
                    </div>
                    {/* <div className="col-sm">
                            One of three columns
                        </div> */}
                    {/* <div className="col-sm">
                            One of three columns
                        </div> */}
                </div>
            </div>
            <br></br>
            <br></br>
            <hr></hr>
            <div className="card-actions">
                <a href="mailto:marathipages@gmail.com?subject=Marathi%20Page%20Creation%20For%20My%20Business&body=message%20goes%20here" target="_blank" rel="noopener noreferrer" className="card-action hand">
                    <i className="fa fa-envelope"></i>
                </a>
                <a href="https://www.facebook.com/marathipages/" target="_blank" rel="noopener noreferrer" className="card-action hand">
                    <i className="fab fa-facebook"></i>
                </a>
                <a href="https://www.youtube.com/channel/UCdSr3cYutf9gLy1i7YTkiBg" target="_blank" rel="noopener noreferrer" className="card-action hand">
                    <i className="fab fa-youtube"></i>
                </a>
                <a href="https://www.instagram.com/marathipages/" target="_blank" rel="noopener noreferrer" className="card-action hand">
                    <i className="fab fa-instagram"></i>
                </a>
                <a href="https://t.me/marathipages" target="_blank" rel="noopener noreferrer" className="card-action hand">
                    <i className="fab fa-telegram"></i>
                </a>
            </div>

            {/* <div>
                    <p className="small text-center text-muted">
                        <a href="mailto:marathipages@gmail.com?subject=Marathi%20Page%20Creation%20For%20My%20Business&body=message%20goes%20here">
                            मराठी Pages वर आजच आपल्या व्यवसायाची जाहिरात करण्यासाठी ह्या लिंक वर क्लिक करा.
                        </a> | &nbsp;
                        <a href="https://www.facebook.com/marathipages/" target="_blank" rel="noopener noreferrer">
                            नवनवीन अपडेट्स साठी आमच्या फेसबुक पेज Like आणि Share करण्यासाठी ह्या लिंक वर क्लिक करा.
                        </a></p>
                </div> */}
            <div className="container">
                <div className="small text-center text-muted">
                    <a href="/terms-conditions" style={{ textDecoration: 'none', color: '#6c757d' }}>Terms & Conditions</a> | <a href="/privacy-policy" style={{ textDecoration: 'none', color: '#6c757d' }}>Privacy Policy</a>
                </div>
            </div>

            <div className="container">
                <div className="small text-center text-muted">
                    Copyright © {COPY_RIGHT_YEAR} - मराठी Pages
                </div>
            </div>
        </footer>
    </>
}

export default FooterComponent;