import React from 'react';
import { connect } from 'react-redux';
import { Card } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { UtilService } from '../../../shared/services/util.service';
import { setCategoriesAction } from '../../../filters/redux/filters.action'

const TopCategoriesComponent = (props) => {

    const history = useHistory();

    const navigateToSearchPageWithCategory = (_categoryId) => {
        props.setCategoriesAction({ [_categoryId]: true })
        history.push('/pages');
    }

    let navigateToSearchPage = (_page) => {
        history.push('/pages');
    }

    return <section className="page-section text-center">
        <div className="container">
            <h2 className="text-center mt-0">TOP CATEGORIES</h2>
            <hr className="divider my-4" />
            <div className="row">
                {props?.categories?.map((_category, _index) => {
                    return _index < 6 ? <div key={_category._id} className="col-lg-2 col-md-6 col-6 text-center">
                        <div className="mt-5">
                            <Card className="hand" style={{ background: 'whitesmoke' }} onClick={() => navigateToSearchPageWithCategory(_category._id)}>
                                <div style={{ padding: '10px' }}>
                                    <div className="card-image" style={{ backgroundImage: 'url(' + _category.imageUrl + ')' }} variant="top" ></div>
                                </div>
                                <Card.Body style={{ textAlign: 'left' }}>
                                    <span>
                                        {UtilService.truncateString(_category.displayName, 13)}
                                    </span><br></br>
                                </Card.Body>
                            </Card>
                        </div>
                    </div> : <div key={_index}></div>
                })}
            </div>
            <br></br>
            <div style={{ textAlign: 'left' }}>
                <button onClick={navigateToSearchPage} type="button" className="btn btn-warning btn-whitesmoke text-white">show more</button>
            </div>
        </div>
    </section>
}

const mapDispatchToProps = (_dispatch) => {
    return {
        setCategoriesAction: (_categories) => _dispatch(setCategoriesAction(_categories))
    }
}

const mapStateToProps = (_state) => {
    return {
        categories: _state.category.list
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopCategoriesComponent);