import React from 'react';
import { connect } from 'react-redux';

const CoverWebViewComponent = (props) => {
    return <>
        {
            props?.page?.coverWebView?.length > 2 ?
                <section className="page-section bg-primary text-center">
                    <div className="container">
                        <h2 className="text-white mt-0">Site Preview</h2>
                        <hr className="divider light my-4" />
                        <br></br>
                        <br></br>
                        <div className="row justify-content-center">
                            <div className="embed-responsive embed-responsive-16by9 align-middle">
                                <iframe title="coverWebview" className="embed-responsive-item" src={props.page.coverWebView} style={{ height: '500px' }} ></iframe>
                            </div>
                        </div>
                        <a target="_blanck" className="btn btn-light btn-xl js-scroll-trigger" href={props.page.coverWebView}>Visit</a>
                    </div>
                    <div style={{
                        fontSize: '10px',
                        color: 'brown',
                        textAlign: 'center'
                    }}><small>*soon this will be deprecated contact admin</small>
                    </div>
                </section>
                : ''
        }
    </>
}

const mapStateToProps = (state) => {
    return {
        page: state.mpage.data || {},
    }
}

export default connect(mapStateToProps, null)(CoverWebViewComponent);