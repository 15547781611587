import { put, takeEvery } from 'redux-saga/effects';
import ReactGA from "react-ga";

import {
    fetchPageDetailsFailedAction,
    fetchPageDetailsSucceededAction,
    incPageViewAction,
    incPageViewSuccessAction,
    incPageViewFailedAction
} from './m-page.action';

function* incPageView(action) {
    let _page = action.payload.page;
    let views = _page.views
    if (!views) {
        views = 0
    }
    let payload = {
        views: views + 1
    }
    let url = '/api/increamentViewsByOne/' + _page._id;
    const settings = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
    };
    try {
        let response = yield fetch(url, settings);
        let data = yield response.json();
        if (data && data.info === 'updated') {
            _page.views += 1;
            yield put(incPageViewSuccessAction(_page));
        }
    } catch (e) {
        yield put(incPageViewFailedAction(e.message));
        console.log(e);
    }
}

function* fetchPageDetails(action) {
    try {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname + window.location.search);
        let response = yield fetch('/api/page/' + action.payload.pageId);
        let page = yield response.json();
        if (page.data && page.data.categoryNext) {
            try {
                let catRes = yield fetch('/api/category/' + page.data.categoryNext);
                let category = yield catRes.json();
                page.data.categoryNext = category;
            } catch (error) {
                console.log(error)
            }
        }
        if (page && page.data && page.data._id) {
            if (page.data.pagescript) {
                setTimeout(() => {
                    try {
                        window.eval(page.data.pagescript);
                    } catch (error) {
                        console.log(error);
                    }
                }, 1000)
            }
            yield put(fetchPageDetailsSucceededAction(page));
            yield put(incPageViewAction(page.data));
            // updateView(page.data);
            // this.setState({ ...this.state, ...page.data });
            // if (page.data.status === 'DRAFT' || page.data.status === 'UN_PUBLISHED') {
            //     if (this.props?.profile?.user?.role === '0') { console.log('superadmin') } else {
            //         this.setState({ ...this.state, ...{ blurPage: { opacity: 0.1 } } })
            //     }
            // }
        } else {
            console.log(page.err);
        }
    } catch (e) {
        yield put(fetchPageDetailsFailedAction(e.message));
    }
}

function* mPageSaga() {
    yield takeEvery("PAGE_DETAILS_FETCH_REQUESTED", fetchPageDetails);
    yield takeEvery("INC_PAGE_VIEW", incPageView);
}

export default mPageSaga;