import React from 'react';

import {
    Switch,
    Route,
    withRouter
} from "react-router-dom";

import ReactGA from "react-ga";
import { store } from './store';

import './assets/fontawesome/css/all.min.css';
import './font.css'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './app.css';
import './index.css';
import SearchPageComponent from './pages/search/search-page.component';
import AddYourBusinessPageComponent from './pages/add-your-bussiness/add-your-bussiness-page.component';
import TermsAndConditionsPageComponent from './pages/terms-and-condition/terms-and-condition-page.component';
import PrivacyPolicyPageComponent from './pages/privacy-policy/privacy-policy-page.component';
import { EnquiryForm } from './classes/enquiry-form';
import { fetchCategoriesAction } from './category/redux/category.action';
import { fetchSponsoredPagesAction, fetchTopPagesAction } from './mpages/redux/mpages.action';
import MPageComponent from './m-page/components/m-page/m-page.component';
import { GOOGLE_ANALYTICS_INIT_KEY } from './shared/constants';
import HomePageComponent from './pages/home/home-page.component'

window.enquiryFormClass = EnquiryForm;

ReactGA.initialize(GOOGLE_ANALYTICS_INIT_KEY);

class App extends React.Component {

    componentDidMount = async () => {

        ReactGA.pageview(window.location.pathname + window.location.search);

        store.dispatch(fetchCategoriesAction());

        store.dispatch(fetchSponsoredPagesAction());

        store.dispatch(fetchTopPagesAction());

    }

    render() {
        return (
            <Switch>
                <Route exact path="/" component={HomePageComponent} />
                <Route path="/page/:id" component={MPageComponent} />
                <Route path="/pages" component={SearchPageComponent} />
                <Route path="/add-your-business" component={AddYourBusinessPageComponent} />
                <Route path="/terms-conditions" component={TermsAndConditionsPageComponent} />
                <Route path="/privacy-policy" component={PrivacyPolicyPageComponent} />
            </Switch>
        );
    }
}

export default withRouter(App)