import { combineReducers } from 'redux';
import { categoryReducer } from './category/redux/category.reducer';
import { mPageReducer } from './m-page/redux/m-page.reducer';
import { filtersReducer } from './filters/redux/filters.reducer';
import { mpagesReducer } from './mpages/redux/mpages.reducer'

export const RootReducer = combineReducers({
    category: categoryReducer,
    mpages: mpagesReducer,
    filters: filtersReducer,
    mpage: mPageReducer
});