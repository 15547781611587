import { createStore, applyMiddleware, compose } from 'redux';
import { logger } from 'redux-logger';
import { RootReducer } from './root-reducer';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from './root-saga';

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = applyMiddleware(logger, sagaMiddleware);

export const store = createStore(RootReducer, composeEnhancers(middleware));


sagaMiddleware.run(rootSaga)