import React from 'react';
import { connect } from 'react-redux';

const PriceSectionComponent = (props) => {
    return <>
        <section className="page-section bg-primary" id="about">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 text-center">
                        <h2 className="text-white mt-0">Price</h2>
                        <hr className="divider light my-4" />
                        {/* <p className="text-white-50 mb-4">Start Bootstrap has everything you need to get your new website up and running in no time! Choose one of our open source, free to download, and easy to use themes! No strings attached!</p> */}
                        <span className="btn btn-light btn-xl js-scroll-trigger">&#x20B9; {props?.page?.price}</span>
                    </div>
                </div>
            </div>
        </section>
        <div style={{
            fontSize: '10px',
            color: 'brown',
            textAlign: 'center'
        }}><small>*soon this will be deprecated contact admin</small>
        </div>
    </>
}

const mapStateToProps = (state) => {
    return {
        page: state.mpage.data || {},
    }
}

export default connect(mapStateToProps, null)(PriceSectionComponent)