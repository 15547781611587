import React from 'react';
import { connect } from 'react-redux';
import MediaComponent from '../media-component/media-component.component';

const SectionMediaGridThreeByComponent = (props) => {
    return <>
        {props?.page?.medias?.length ?
            <div id="portfolio">
                <div className="container-fluid p-0">
                    <div className="row no-gutters">
                        {props?.page?.medias?.map((med, index) => {
                            return <div key={index} className="col-lg-4 col-sm-6"> <a className="portfolio-box">
                                <MediaComponent media={med} />
                                <div className="portfolio-box-caption">
                                    <div className="project-category text-white-50">{med.captionOne}</div>
                                    <div className="project-name">{med.captionTwo}</div>
                                </div>
                            </a></div>
                        })}
                    </div>
                </div>
            </div>
            : ''}
    </>
}

const mapStateToProps = (state) => {
    return {
        page: state.mpage.data || {},
    }
}

export default connect(mapStateToProps, null)(SectionMediaGridThreeByComponent)