import React from 'react';
import { connect } from 'react-redux';

const PricingPlansComponent = (props) => {
    return <>
        <section className="page-section bg-primary" id="about">
            <div className="container">
                <div className="text-center">
                    <h2 className="text-white mt-0">PRICING PLANS</h2>
                    <hr className="divider light my-4" />
                    <br />
                    <div className="container">
                        <div className="row pt-4">
                            {
                                props?.page?.pricingPlans?.map((pln, index) => {
                                    return <div key={index} className={pln.class}>
                                        <div className="card mb-4 box-shadow">
                                            <div className="card-header">
                                                {/* <i className="fas fa-4x fa-gem text-primary mb-4"></i> */}
                                                <h4 className="my-0 font-weight-normal">{pln.name}</h4>
                                            </div>
                                            <div className="card-body">
                                                <h3>
                                                    <b>&#x20B9; {pln.price} </b>
                                                    <small className="text-muted">{pln.priceDesc}</small>
                                                </h3>
                                                <ul className="list-unstyled mt-3 mb-4" dangerouslySetInnerHTML={{ __html: pln?.desc }}></ul>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div style={{
            fontSize: '10px',
            color: 'brown',
            textAlign: 'center'
        }}><small>*soon this will be deprecated contact admin</small>
        </div>
    </>
}

const mapStateToProps = (state) => {
    return {
        page: state.mpage.data || {},
    }
}

export default connect(mapStateToProps, null)(PricingPlansComponent)