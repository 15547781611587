let mpage = {};

export const mPageReducer = (state = mpage, action) => {
    switch (action.type) {
        case 'PAGE_DETAILS_FETCH_SUCCEEDED': return {
            ...state,
            ...action.payload.pageDetails
        }
        case 'INC_PAGE_VIEW_SUCCESS': return {
            ...state,
            data: action.payload.page
        }
        default: return state
    }
}