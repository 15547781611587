export class UtilService {

    static defaultExceedString = '...';
    static defaultLimit = 15;

    static getExceedString(_exceedString) {
        return _exceedString ? _exceedString : UtilService.defaultExceedString
    }

    static getStringLimit(_limit) {
        return _limit > 0 ? _limit : this.defaultExceedString;
    }

    static truncateString(_string, _limit, _exceedString) {
        if (_string && _string.length > this.getStringLimit(_limit)) {
            _string = _string.substring(0, _limit) + this.getExceedString(_exceedString)
        }
        return _string;
    }
};