let categoriesInitState = {
    sponsoredPages: [],
    topPages: [],
};
export const mpagesReducer = (state = categoriesInitState, action) => {
    switch (action.type) {
        case 'SPONSORED_PAGES_FETCH_SUCCEEDED': return (() => {
            return { ...state, ...{ sponsoredPages: action.payload.sponsoredPages } }
        })();
        case 'TOP_PAGES_FETCH_SUCCEEDED': return (() => {
            return { ...state, ...{ topPages: action.payload.topPages } }
        })();
        case 'ALL_PAGES_FETCH_SUCCEEDED': return (() => {
            return { ...state, ...{ allPages: action.payload.allPages } }
        })();
        default: return (() => {
            return state
        })();
    }
}