import React from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';

import {
    setCategoriesAction,
    setRatingsAction,
    setPageSizeAction,
    toggleFiltersAction,
    setAppliedFiltersAction
} from '../../redux/filters.action';


import { fetchAllPagesAction } from '../../../mpages/redux/mpages.action'

const FiltersComponent = (props) => {

    let ratings = [
        { displayName: '5 Stars', rating: 5 },
        { displayName: '4 Stars & Above', rating: 4 },
        { displayName: '3 Stars & Above', rating: 3 },
        { displayName: '2 Stars & Above', rating: 2 },
        { displayName: '1 Star & Above', rating: 1 }
    ]

    let pageSizes = [10, 20, 50, 100];

    let onPageSizeChange = (ev) => {
        props.filters.pageSize = parseInt(ev.target.value);
        props.setPageSizeAction(props.filters.pageSize);
    }

    let onCategoriesChange = (ev) => {
        let isChecked = ev.target.checked;
        let catId = ev.target.id;
        props.filters.categories[catId] = isChecked
        props.setCategoriesAction(props.filters.categories);
    }

    let onRatingsChange = (ev) => {
        let ratings = ev.target.value;
        props.setRatingsAction(parseInt(ratings));
    }

    let applyFilters = () => {
        props.fetchAllPagesAction(props.filters);
        props.toggleFiltersAction();
        props.setAppliedFiltersAction();
    }

    // let closeFiltersView = () => {
    //     props.togglefilters();
    // }

    return <div className='p-3'>
        <div className='p-2'>Categories
            {/* <button className='btn btn-secondary btn-sm float-right'>Clear All</button> */}
        </div>
        <div className='p-3'>
            <Form className='row'>
                {props?.categories?.map((_category, index) => {
                    return <div key={_category.displayName} className="col-md-3 mb-3">
                        <Form.Check
                            inline
                            label={_category.displayName}
                            name={_category.name}
                            type='checkbox'
                            id={_category._id}
                            onChange={onCategoriesChange}
                            checked={props.filters.categories[_category._id]}
                        />
                    </div>
                })}
            </Form>
        </div>
        <hr></hr>
        <div className='p-2'>Ratings</div>
        <div className='p-3'>
            <Form className='row'>
                {ratings.map((_element, index) => {
                    return <div key={_element.rating} className="col-md-3 mb-3">
                        <Form.Check
                            inline
                            label={_element.displayName}
                            name={_element.rating}
                            value={_element.rating}
                            type='checkbox'
                            id={_element.rating}
                            onChange={onRatingsChange}
                            checked={props.filters.ratings === _element.rating}
                        />
                    </div>
                })}
            </Form>
        </div>
        <hr></hr>
        <div className='p-2'>Page Size</div>
        <div className='p-3'>
            <Form className='row'>
                {pageSizes.map((_pageLimit, index) => {
                    return <div key={_pageLimit} className="col-md-3 mb-3">
                        <Form.Check
                            inline
                            label={_pageLimit}
                            type='radio'
                            onChange={onPageSizeChange}
                            id={_pageLimit}
                            value={_pageLimit}
                            checked={props.filters.pageSize === _pageLimit}
                        />
                    </div>
                })}
            </Form>
        </div>
        <hr></hr>
        <div className='p-3 float-right'>
            {/* <button className='btn btn-secondary' onClick={() => closeFiltersView()}>Close</button> */}
            &nbsp;&nbsp;<button className='btn btn-primary' onClick={() => applyFilters()}>Apply</button>
        </div>

    </div>
}

const mapDispatchToProps = (_dispatch) => {
    return {
        setCategoriesAction: (_categories) => _dispatch(setCategoriesAction(_categories)),
        setRatingsAction: (_ratings) => _dispatch(setRatingsAction(_ratings)),
        setPageSizeAction: (_pageSize) => _dispatch(setPageSizeAction(_pageSize)),
        fetchAllPagesAction: (_filters) => _dispatch(fetchAllPagesAction(_filters)),
        toggleFiltersAction: (_filters) => _dispatch(toggleFiltersAction()),
        setAppliedFiltersAction: (_filters) => _dispatch(setAppliedFiltersAction()),
    }
}

const mapStateToProps = (_state) => {
    return {
        categories: _state.category.list,
        filters: _state.filters
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FiltersComponent);