import React, { useEffect } from 'react';
import NavbarComponent from '../../../shared/components/navbar/navbar.component';
import TopPagesComponent from '../../../mpages/components/top-pages/top-pages.component';
import { fetchPageDetailsAction } from '../../redux/m-page.action';
import { connect } from 'react-redux';
import SectionMediaGridThreeByComponent from '../section-media-grid-three-by/section-media-grid-three-by.component';
import ProductsSectionComponent from '../products-section/products-section.component';
import PriceSectionComponent from '../price-section/price-section.component';
import PricingPlansComponent from '../pricing-plans/pricing-plans.component';
import CoverSectionComponent from '../cover-section/cover-section.component';
import ContactsSectionComponent from '../contact-section/contact-section.component'
import CoverVideoComponent from '../cover-video/cover-video.component';
import SectionComponent from '../section/section.component';
import FooterComponent from '../../../shared/components/footer/footer.component';
import CoverWebViewComponent from '../cover-web-view/cover-web-view.component';
import SectionTitleFourCardsComponent from '../section-title-four-cards/section-title-four-cards.component';

const MPageComponent = (props) => {

    useEffect(() => {
        props.fetchPageDetailsAction(props.match.params.id);
    }, [])

    return <>
        <NavbarComponent />
        <div >
            <CoverSectionComponent></CoverSectionComponent>

            <CoverVideoComponent></CoverVideoComponent>
            {
                props?.page?.coverWebView ? <CoverWebViewComponent /> : ''
            }
            {
                props?.page?.descriptions?.length ? <SectionTitleFourCardsComponent /> : ''
            }
            {
                props?.page?.products ? <ProductsSectionComponent /> : ''
            }
            {
                props?.page?.price ? <PriceSectionComponent /> : ''
            }
            {
                props?.page?.pricingPlans?.length ? <PricingPlansComponent /> : ''
            }
            <SectionComponent />

            <SectionMediaGridThreeByComponent />

            <ContactsSectionComponent></ContactsSectionComponent>


            <div className="mt-n6 container">
                {/* <h2 className="text-center mt-0">Tags</h2>
                        <hr className="divider my-4" /> */}
                <div className="row">
                    <div className='col-md-12'>
                        {
                            props?.page?.metaKeywords?.split(',').map((key, index) => {
                                return <span key={index} className="mr-1 badge badge-light" style={{ fontSize: '10px', background: 'lightgray' }}>{key}</span>
                            })
                        }
                    </div>
                </div>
            </div>

            <TopPagesComponent fromSamePage={true}></TopPagesComponent>

        </div>
        <FooterComponent />
    </>
}

const mapDispatchToProps = (_dispatch) => {
    return {
        fetchPageDetailsAction: (_pageId) => _dispatch(fetchPageDetailsAction(_pageId)),
    }
}

const mapStateToProps = (_state) => {
    return {
        page: _state.mpage.data || {},
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MPageComponent);