import React, { useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';

const AddBusinessFormComponent = (props) => {

    const [form, setForm] = useState({});
    const [msg, setMsg] = useState('');
    const [validated, setValidated] = useState(false);
    const [className, setClassName] = useState('');
    const [saving, setSaving] = useState(false);

    const onInputChange = (e) => {
        let obj = {}
        obj[e.target.id] = e.target.value;
        setForm({ ...form, ...obj })
    }

    const resetState = () => {
        setForm({});
        setMsg('');
        setValidated(false);
        setClassName('');
        setSaving(false);
    }

    const addInquiry = async (e) => {
        e.preventDefault();
        setValidated(true);
        if (e.target.checkValidity()) {
            setSaving(true);
            let _form = document.getElementById('inquiryForm');
            let url = '/api/inquiry'
            const settings = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(form)
            };
            try {
                let response = await fetch(url, settings);
                let data = await response.json();
                if (data && data._id) {
                    setMsg('Request submitted, we connect you soon !');
                    setClassName('alert alert-info');
                    setTimeout(() => {
                        resetState();
                        _form.reset();
                    }, 3000)
                }
            } catch (error) {
                setMsg('Your request can not be completed now please try again later !');
                setClassName('alert alert-danger');
                setTimeout(() => {
                    resetState();
                    _form.reset();
                }, 3000)
            }
        }
    }

    return <>
        <section className="" id="addYourBusiness">
            <div className="container">
                <h2 className="text-center mt-0 text-white">Add Your Business</h2>
                <hr className="divider my-4" />
                <Form id="inquiryForm" onSubmit={addInquiry} noValidate validated={validated} autoComplete="off">
                    <Form.Group controlId="firstName">
                        <Form.Label style={{ color: 'whitesmoke' }}>First Name *</Form.Label>
                        <Form.Control type="text" className="form-control" required onChange={onInputChange} />
                        <Form.Control.Feedback type="invalid">
                            Please enter firstName.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="lastName">
                        <Form.Label style={{ color: 'whitesmoke' }}>Last Name *</Form.Label>
                        <Form.Control type="text" className="form-control" required onChange={onInputChange} />
                        <Form.Control.Feedback type="invalid">
                            Please enter lastname.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="email">
                        <Form.Label style={{ color: 'whitesmoke' }}>Email *</Form.Label>
                        <Form.Control type="email" className="form-control" required onChange={onInputChange} />
                        <Form.Control.Feedback type="invalid">
                            Please enter valid email.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="mobileNumber">
                        <Form.Label style={{ color: 'whitesmoke' }}>Mobile Number *</Form.Label>
                        <Form.Control type="number" className="form-control" required onChange={onInputChange} />
                        <Form.Control.Feedback type="invalid">
                            Please enter valid mobile.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <div className={className} role="alert">
                        {msg}
                    </div>
                    <div>
                        {saving ?
                            <button className="btn btn-primary btn-block text-white">
                                <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                saving...
                            </button> :
                            <button type="submit" className="btn btn-primary btn-block text-white">SUBMIT</button>
                        }
                    </div>
                </Form>
            </div>
        </section>
    </>
}

export default AddBusinessFormComponent;