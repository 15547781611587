import { put, takeEvery } from 'redux-saga/effects';

function* fetchSponsoredPages(action) {
    try {
        let sponsoredPagesUrl = '/api/pages-summary?status=PUBLISHED&feedVisiblity=true&sponsored=true&limit=8&sort=views%20Desc';
        let sponsoredPagesRes = yield fetch(sponsoredPagesUrl);
        sponsoredPagesRes = yield sponsoredPagesRes.json();
        if (sponsoredPagesRes?.count) {
            yield put({ type: "SPONSORED_PAGES_FETCH_SUCCEEDED", payload: { sponsoredPages: sponsoredPagesRes.list || [] } });
        }
    } catch (e) {
        yield put({ type: "SPONSORED_PAGES_FETCH_FAILED", message: e.message });
    }
}

function* fetchTopPages(action) {
    try {
        let topPagesUrl = '/api/pages-summary?status=PUBLISHED&feedVisiblity=true&limit=8&sort=views%20Desc';
        let topPagesRes = yield fetch(topPagesUrl);
        topPagesRes = yield topPagesRes.json();
        if (topPagesRes?.count) {
            yield put({ type: "TOP_PAGES_FETCH_SUCCEEDED", payload: { topPages: topPagesRes.list || [] } });
        }
    } catch (e) {
        yield put({ type: "TOP_PAGES_FETCH_FAILED", message: e.message });
    }
}

function* fetchAllPages(action) {
    try {
        let allPagesUrl = '/api/pages-search';
        let queryParams = {};
        if (action.payload) {
            if (action.payload.pageSize > 0) {
                queryParams.limit = action.payload.pageSize;
            }
            let _categories = [];
            if (action.payload.categories) {
                const keys = Object.keys(action.payload.categories);
                keys.forEach((key, index) => {
                    if (action.payload.categories[key]) {
                        _categories.push(key)
                    }
                    // console.log(`${key}: ${action.payload.categories[key]}`);
                });
                queryParams.categories = _categories;
            }
            if (action.payload.ratings) {
                queryParams.stars = action.payload.ratings
            }
            if (action.payload.searchText) {
                queryParams.word = action.payload.searchText
            }
        }
        // allPagesUrl = getQueryParamUrl(allPagesUrl, queryParams);
        // console.log(allPagesUrl);
        let allPagesRes = yield fetch(allPagesUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(queryParams)
        });
        allPagesRes = yield allPagesRes.json();
        if (allPagesRes?.count) {
            yield put({ type: "ALL_PAGES_FETCH_SUCCEEDED", payload: { allPages: allPagesRes.list || [] } });
        }
    } catch (e) {
        yield put({ type: "ALL_PAGES_FETCH_FAILED", message: e.message });
    }
}

function* mpagesSaga() {
    yield takeEvery("SPONSORED_PAGES_FETCH_REQUESTED", fetchSponsoredPages);
    yield takeEvery("TOP_PAGES_FETCH_REQUESTED", fetchTopPages);
    yield takeEvery("ALL_PAGES_FETCH_REQUESTED", fetchAllPages);
}

export default mpagesSaga;