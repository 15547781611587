import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import MpageCardComponent from '../mpage-card/mpage-card.component';

const TopPagesComponent = (props) => {

    const history = useHistory();

    let navigateToPages = () => {
        history.push('/pages');
    }

    return <section className="page-section text-center">
        <div className="container">
            <h2 className="text-center mt-0">TOP PAGES</h2>
            <hr className="divider my-4" />
            <div className="row">
                {props?.topPages?.map((page, index) => {
                    return page.status === 'PUBLISHED' ? <div key={index} className="col-lg-3 col-md-6 text-center">
                        <div className="mt-5">
                            <MpageCardComponent page={page} fromSamePage={props.fromSamePage}></MpageCardComponent>
                        </div>
                    </div> : ''
                })}
            </div>
            <br></br>
            <div style={{ textAlign: 'left' }}>
                <button onClick={navigateToPages} type="button" className="btn btn-warning btn-whitesmoke text-white">show more</button>
            </div>
        </div>
    </section>
}

const mapDispatchToProps = (_dispatch) => {
    return {}
}

const mapStateToProps = (_state) => {
    return {
        topPages: _state.mpages.topPages
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TopPagesComponent);