import React from 'react';
import { Image } from 'react-bootstrap';

const MediaComponent = (props) => {
    return <>
        {props?.media?.type === 'emd' ?
            <div className="embed-responsive embed-responsive-16by9 align-middle">
                <iframe title="iframeMedia" className="embed-responsive-item" src={props?.media?.url} allowFullScreen></iframe>
            </div>
            :
            <Image src={props?.media?.url} fluid />
        }
    </>
}

export default MediaComponent