import React from 'react';
import { Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { fetchPageDetailsAction } from '../../../m-page/redux/m-page.action'

const MpageCardComponent = (props) => {

    const history = useHistory();

    let navigateToPage = (_page) => {
        if (props.fromSamePage) {
            props.fetchPageDetailsAction(_page.unqUrl);
        }
        history.push('/page/' + _page.unqUrl);
    }

    const stripHtml = (htmlString) => {
        return htmlString ? htmlString.replace(/<[^>]*>?/gm, ' ').replace(/\s+/g, ' ').trim() : ''
    }

    const truncate = (str, lengthLimit) => {
        return str && str.length > lengthLimit ? str.substring(0, lengthLimit) + ".." : str;
    }

    let getStars = (num) => {
        var indents = [];
        let intNum = parseInt(num);
        for (var i = 0; i < intNum; i++) {
            indents.push(<i key={i} className="fas fa-star" style={{ fontSize: '12px', color: 'orange' }}></i>);
        }
        return indents;
    }

    let getCardImage = (_page) => {
        return _page.thumbnail ? _page.thumbnail : _page.coverImage
    }

    return <Card className="hand" style={{ background: 'whitesmoke' }} onClick={() => navigateToPage(props.page)}>
        <div style={{ padding: '10px' }}>
            <div className="card-image" style={{ backgroundImage: 'url(' + getCardImage(props.page) + ')' }} variant="top" ></div>
        </div>
        <Card.Body style={{ textAlign: 'justify' }}>
            <span>
                {truncate(stripHtml(props.page.title), 20)}
            </span><br></br>
            <span>
                {props.page.address ? truncate(props.page.address, 20) : '-'}
            </span><br></br>
            <span>
                {props.page.stars ? getStars(props.page.stars) : 'no ratings yet'}
                <span style={{ fontSize: '10px', padding: '8px', float: 'right' }}>
                    <i className="fas fa-eye text-muted"></i> &nbsp;
                    <b>{props.page.views}</b>
                </span>
            </span>
            {/* <hr></hr>
            <span style={{ fontSize: '10px' }}>
                created on
                &nbsp;
                <b>{props.page.createdOn ? new Date(props.page.createdOn).toDateString() : ''}</b>
            </span>
            <br></br>
            <span style={{ fontSize: '10px' }}>
                last updated on
                &nbsp;
                <b>{props.page.updatedOn ? new Date(props.page.updatedOn).toDateString() : ''}</b>
            </span> */}
        </Card.Body>
    </Card>
}
const mapDispatchToProps = (_dispatch) => {
    return {
        fetchPageDetailsAction: (_pageId) => _dispatch(fetchPageDetailsAction(_pageId)),
    }
}

export default connect(null, mapDispatchToProps)(MpageCardComponent);