import React from "react";
import { connect } from "react-redux";

const SectionComponent = (props) => {
    return (
        <>
            {props?.page?.sections?.map((section, index) => {
                return (
                    <section
                        key={index}
                        className={"page-section " + section.class}
                        id="productsSection"
                    >
                        <div className="container">
                            {section.title ? (
                                <>
                                    <h3
                                        className="text-center mt-0"
                                        dangerouslySetInnerHTML={{
                                            __html: section?.title,
                                        }}
                                    ></h3>
                                    <hr className="divider my-4" />
                                </>
                            ) : (
                                ""
                            )}
                            <div className="row">
                                {section.items?.map((item, index) => {
                                    return (
                                        <div key={index} className={item.class}>
                                            <div className="mt-5">
                                                {item.url ? (
                                                    <>
                                                        <div
                                                            className="card-image"
                                                            style={{
                                                                backgroundImage:
                                                                    "url(" +
                                                                    item.url +
                                                                    ")",
                                                            }}
                                                            variant="top"
                                                        ></div>
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                                {item.icon ? (
                                                    <>
                                                        <i
                                                            className={
                                                                item.icon
                                                            }
                                                        ></i>
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                                {item.title ? (
                                                    <>
                                                        <h4
                                                            className="mb-2 mt-4"
                                                            dangerouslySetInnerHTML={{
                                                                __html: item?.title,
                                                            }}
                                                        ></h4>
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                                {item.price ? (
                                                    <>
                                                        <div className="mb-2 mt-4">
                                                            <b>
                                                                &#x20B9;{" "}
                                                                {item.price}{" "}
                                                            </b>
                                                            <small>
                                                                {item.priceDesc}
                                                            </small>
                                                        </div>
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                                {item.body ? (
                                                    <>
                                                        <p
                                                            className="mb-0 mt-4"
                                                            dangerouslySetInnerHTML={{
                                                                __html: item?.body,
                                                            }}
                                                        ></p>
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </section>
                );
            })}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        page: state.mpage.data || {},
    };
};

export default connect(mapStateToProps)(SectionComponent);
