import React, { useEffect } from 'react';
import NavbarComponent from '../../shared/components/navbar/navbar.component';
import AddBusinessFormComponent from '../../shared/components/add-business-form/add-business-form.component';
import FooterSmallComponent from '../../shared/components/footer-small/footer-small.component'
import ReactGA from "react-ga";

const AddYourBusinessPageComponent = () => {

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return <>
        <NavbarComponent />
        <br></br>
        <br></br>
        <br></br>
        <section className="pt-5 bg-dark">
            <div class="container">
                <div class="row">
                    <div class="col-sm">
                        <AddBusinessFormComponent />
                    </div>
                </div>
            </div>
            <hr></hr>
        </section>
        <div className="mt-n5">
            <FooterSmallComponent />
        </div>
    </>
}

export default AddYourBusinessPageComponent