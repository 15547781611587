import React from 'react';
import { connect } from 'react-redux';
import MediaComponent from '../media-component/media-component.component'

const SectionTitleFourCardsComponent = (props) => {
    return <section className="page-section" id="services">
        <div className="container">
            <h2 className="text-center mt-0">{props?.page?.descTitle}</h2>
            <hr className="divider my-4" />
            <div className="row">
                {props?.page?.descriptions?.map((desc, index) => {
                    return <div key={index} className="col-lg-3 col-md-6 text-center">
                        <div className="mt-5">
                            {desc.url ? <><MediaComponent media={{ url: desc.url, type: desc.type }} /> <br></br></> : ''}
                            <h3 className="h4 mb-2" dangerouslySetInnerHTML={{ __html: desc?.title }}></h3>
                            <p className="text-muted mb-0" dangerouslySetInnerHTML={{ __html: desc?.value }}></p>
                        </div>
                    </div>
                })}
            </div>
        </div>
        <div style={{
            fontSize: '10px',
            color: 'brown',
            textAlign: 'center'
        }}><small>*soon this will be deprecated contact admin</small>
        </div>
    </section>
}

const mapStateToProps = (state) => {
    return {
        page: state.mpage.data || {},
    }
}

export default connect(mapStateToProps, null)(SectionTitleFourCardsComponent)